import React, { useState } from "react";
import styles from "../../styles/info-site/ContactPageRequestDemo.module.css";
import { FormattedMessage } from "react-intl";

import tyvisoLogo from "../../assets/simple-logo.png"
import { sendEmail } from "../../api";

const ContactPageRequestDemo = ({children}) => {
    const [isFormLoaded, setIsFormLoaded] = useState(false);
    const [success, setSuccess] = useState(false);
    const [values, setValues] = useState({
      name: '',
      email: '',
      message: '',
      company: ''
    })

    const handleSubmit = e => {
      e.preventDefault();
      e.stopPropagation();

      sendEmail({
        username: "sales@tyviso.com",
        title: "Demo Request",
        content: `New demo request: <br /> <strong>Name:</strong> ${values.name} <br /> <strong>Email:</strong> ${values.email} <br /> <strong>Company:</strong> ${values.company} <br /> <strong>Notes:</strong> ${values.message}`
      }).then(res => {
        if(res) {
          setSuccess(true)
        }
      })
    }

    const handleChange = e => {
      setValues({ ...values, [e.target.name]: e.target.value })
    }

    return (
        <div className={styles.Contact}>
            <div className={styles.Logo}>
              <img src={tyvisoLogo} alt='' />
            </div>
            
          {
            !success
              ? (
                <>
                  <div className={styles.Conainer}>
                    <h2><FormattedMessage id="requestADemo" /></h2>
                    <h3><FormattedMessage id="requestDemoQTour" /></h3>
                    <p><FormattedMessage id="requestDemoDesc" /></p>

                    <form onSubmit={handleSubmit}>

                      <div className={styles.FormRow}>
                        <label htmlFor="name">Your name</label>
                        <input required id='name' name='name' type='name' value={values.name} onChange={handleChange}  />
                      </div> 

                      <div className={styles.FormRow}>
                        <label htmlFor="company">Your company</label>
                        <input required id='company' name='company' type='company' value={values.company} onChange={handleChange}  />
                      </div>

                      <div className={styles.FormRow}>
                        <label htmlFor="email">Your email</label>
                        <input required id='email' name='email' type='email' value={values.email} onChange={handleChange} />
                      </div>

                      <div className={styles.FormRow}>
                        <label htmlFor="message">Any additional notes | optional</label>
                        <textarea rows={3} id='message' name='message' type='message' value={values.message} onChange={handleChange}  />
                      </div> 

                      <input className={styles.FormSubmit} type="submit" value="Request Demo" />
                    </form>
                  </div>
                </>
              ) : (
                <div className={styles.ConainerSuccess}>
                  <h4><FormattedMessage id="gwpQuoteThankYouTitle" /></h4>
                  <p><FormattedMessage id="gwpQuoteThankYouDesc" /></p>
                </div>
              )
          }
        </div>
    );
};

export default ContactPageRequestDemo;