import React from "react";
import Seo from "../components/info-site/Seo";
import Layout from "../layouts/en";
import ContactPageRequestDemo from "../components/info-site/ContactPageRequestDemo";

const Contact = props => {
    return (
        <Layout location={props.location}>
            <Seo titleId="contactTitle" descriptionId="contactDescription" />
            <ContactPageRequestDemo />
        </Layout>
    );
};

export default Contact;